<template>
  <div style="padding: 24px 16px; height: 100%">
    <div class="presupposition-content full column">
      <div class="header flex flex-wrap">
        <SupplierVersion
          v-model:supplier="supplier"
          v-model:type="type"
          v-model:version="version"
        />
        <div class="flex1"></div>
        <div class="search-button">
          <button class="blue-button" @click="search()">查询</button>
          <!-- <button class="empty-button">导出</button> -->
        </div>
      </div>
      <div class="flex1" id="table">
        <div class="card" v-for="(item, index) in list" :key="index">
          <div class="flex info-bar" style="justify-content: space-between">
            <div class="flex flex-wrap">
              <label>设备商：{{ item.supplier }}</label>
              <label>型号：{{ item.type }}</label>
              <label>版本软件号：{{ item.version }}</label>
            </div>
            <router-link
              class="blue-button"
              :to="{
                path: '/public/presupposition-detail',
                query: { id: item.id },
              }"
              >新增预设</router-link
            >
          </div>
          <div>
            <table>
              <tr class="table-header">
                <th width="40%">预设名称</th>
                <th width="30%">最后更新时间</th>
                <th width="30%">操作</th>
              </tr>
              <tr class="table-row" v-for="(preset, i) in item.list" :key="i">
                <td>{{ preset.name }}</td>
                <td>{{ preset.update_time }}</td>
                <td class="action flex flex-wrap">
                  <router-link
                    class="margin"
                    :to="{
                      path: '/public/presupposition-detail',
                      query: {
                        id: item.id,
                        ifPreview: true,
                        paramId: preset.id,
                      },
                    }">
                    查询</router-link
                  >
                  <router-link
                    class="margin"
                    :to="{
                      path: '/public/presupposition-detail',
                      query: { id: item.id, paramId: preset.id},
                    }">编辑</router-link
                  >
                  <div class="margin" @click="deletePreset(item, preset.id)">
                    删除
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import SupplierVersion from '@/components/supplier-version.vue';
import { LocalPresupposition, Presupposition } from './presupposition.model';
import {
  GetModelParam,
  ModelSimple,
} from '../formwork-list/formwork-list.service';

@Options({
  components: {
    SupplierVersion,
  },
})
export default class MissionList extends Vue {
  public supplier: string = '';
  public type: string = '';
  public version: string = '';
  public list: ModelSimple[] = [];
  public mounted() {
    console.log('mounted');
    this.search();
  }
  public async search() {
    this.$base.loading.show();
    const param: GetModelParam = new GetModelParam();
    param.page_number = 1;
    param.page_size = undefined;
    param.device_supplier = this.supplier;
    param.device_type = this.type;
    param.device_version = this.version;
    const http = await this.$http
      .get<{ list: ModelSimple[]; total: number }>(
        `${process.env.VUE_APP_API}/devices/models`,
        param,
      )
      .then(
        (r) => {
          this.list = r.list;
        },
        (e) => {
          this.$base.error(e);
        },
      );
    const a = await this.$http
      .get<Presupposition[]>(`${process.env.VUE_APP_API}/devices/preset`, {
        supplier: this.supplier,
        type: this.type,
        version: this.version,
      })
      .then(
        (r) => {
          this.list.forEach((model) => {
            model.list = r.filter((val) => val.device_id === model.id);
          });
        },
        (e) => {
          this.$base.error(e);
        },
      );
    this.$base.loading.close();
  }
  public deletePreset(target: LocalPresupposition, id: number) {
    const sure = () => {
      this.$http
        .delete(`${process.env.VUE_APP_API}/devices/preset/${id}`, {})
        .then(() => {
          if (a) {
            target.list.splice(target.list.indexOf(a), 1);
          }
        })
        .catch((e) => {
          this.$base.error(e);
        });
    };
    const a = target.list.find((r) => r.id === id);
    this.$base.info(`确定要删除预设"${a?.name}"吗？`, sure, '提示');
  }
}
</script>
<style lang="less">
.presupposition-content {
  background: unset;
  padding: 0px;
  .header {
    background: white;
    margin-bottom: 24px;
    border-radius: 2px;
    justify-content: space-between;
    padding: 10px 25px;
    .search-item {
      margin: 0px;
      padding-right: 10px;
      input {
        flex: 1;
      }
    }
    .search-button {
      justify-content: flex-end;
      padding-right: 5px;
    }
  }
  .action {
    justify-content: center;
    color: #1890ff;
    .margin {
      cursor: pointer;
      margin: 0px 8px;
    }
  }
  #table {
    border-radius: 2px;
    overflow: auto;
    .card {
      width: 100%;
      background: white;
      padding: 20px;
      margin-bottom: 15px;
      .info-bar {
        margin-bottom: 15px;
        label {
          line-height: 30px;
          margin-right: 30px;
          font-size: 14px;
          font-weight: 600;
        }
        label:nth-last-child(1) {
          margin-right: 0px;
        }
      }
      table {
        width: 100%;
        text-align: center;
        .table-header {
          line-height: 54px;
          height: 54px;
          background: #fafafa;
          font-weight: 600;
        }
        .table-row {
          line-height: 54px;
          height: 54px;
          border-top: solid 1px #eeeeee;
        }
        th,
        td {
          text-align: center;
        }
        th:nth-child(1),
        td:nth-child(1) {
          text-align: left;
          padding-left: 10%;
        }
      }
    }
  }
}
</style>