
import { Options, Vue } from 'vue-class-component';
import SupplierVersion from '@/components/supplier-version.vue';
import { LocalPresupposition, Presupposition } from './presupposition.model';
import {
  GetModelParam,
  ModelSimple,
} from '../formwork-list/formwork-list.service';

@Options({
  components: {
    SupplierVersion,
  },
})
export default class MissionList extends Vue {
  public supplier: string = '';
  public type: string = '';
  public version: string = '';
  public list: ModelSimple[] = [];
  public mounted() {
    console.log('mounted');
    this.search();
  }
  public async search() {
    this.$base.loading.show();
    const param: GetModelParam = new GetModelParam();
    param.page_number = 1;
    param.page_size = undefined;
    param.device_supplier = this.supplier;
    param.device_type = this.type;
    param.device_version = this.version;
    const http = await this.$http
      .get<{ list: ModelSimple[]; total: number }>(
        `${process.env.VUE_APP_API}/devices/models`,
        param,
      )
      .then(
        (r) => {
          this.list = r.list;
        },
        (e) => {
          this.$base.error(e);
        },
      );
    const a = await this.$http
      .get<Presupposition[]>(`${process.env.VUE_APP_API}/devices/preset`, {
        supplier: this.supplier,
        type: this.type,
        version: this.version,
      })
      .then(
        (r) => {
          this.list.forEach((model) => {
            model.list = r.filter((val) => val.device_id === model.id);
          });
        },
        (e) => {
          this.$base.error(e);
        },
      );
    this.$base.loading.close();
  }
  public deletePreset(target: LocalPresupposition, id: number) {
    const sure = () => {
      this.$http
        .delete(`${process.env.VUE_APP_API}/devices/preset/${id}`, {})
        .then(() => {
          if (a) {
            target.list.splice(target.list.indexOf(a), 1);
          }
        })
        .catch((e) => {
          this.$base.error(e);
        });
    };
    const a = target.list.find((r) => r.id === id);
    this.$base.info(`确定要删除预设"${a?.name}"吗？`, sure, '提示');
  }
}
